<!-- Footer -->
<footer class="footer text-right">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 font-12">
        &copy; Copyright {{year}} Logajob Australia Pty Ltd. | {{appVersion}}
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->